import React, { useState } from "react";
import VideoModal from "./VideoPlayer";
import GradButton from "../../../components/Button/GradButton/GradButton";
import Navbar from "../../../components/HomeNavbar";
import Circle from "../../../assets/Circle.svg";
import { Button, Divider } from "@mui/material";
import "./LandingPage.css";

const buttonStyle = {
  zIndex: 1000,
  borderRadius: "1.5rem",
  color: "#fff",
  fontWeight: "bold",
  textTransform: "none",
  fontSize: "18px",
  padding: "5px 18px 5px 18px;" /*Top padding 0 bcs font has gap at the top*/,
  background: "linear-gradient(135deg,#C69EEE, #8A14FE, #2B094C)",
};

function LandingPage() {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const handleOpen = (link) => {
    setLink(link);
    setOpen(true);
  };

  const videos = [
    {
      link: "https://enerfacas.s3.eu-north-1.amazonaws.com/tank+software+demo.mp4",
      title: "Watch Video: Storage Tank API-650",
    },
    {
      link: "https://enerfacas.s3.eu-north-1.amazonaws.com/PV+Software+Video+14-11-24.mp4",
      title: "Watch Video: Pressure Vessel",
    },
    {
      link: "https://enerfacas.s3.eu-north-1.amazonaws.com/Skid+Package.mp4",
      title: "Watch Video: Skid Package",
    },
  ];

  const handleClose = () => setOpen(false);

  return (
    <div className="lp-container">
      <div class="hero-section">
        <div className="hero">
          <h4>End to End</h4>

          <div className="title">
            <h1 className="digital">Digital</h1>
            <h1 className="transformation">Transformation</h1>
          </div>
          <p>
            Empower your transformation with our centralized data powerhouse.
            Streamline operations, foster collaboration, and ignite innovation
            seamlessly.
          </p>
          {videos.map((video) => (
            <>
              <Button
                className="video-button"
                variant="contained"
                sx={buttonStyle}
                onClick={() => handleOpen(video.link)}
              >
                <span>{video.title}</span>
              </Button>
              <Divider />
            </>
          ))}
        </div>
        <div className="hero-img">
          <img src={Circle} alt="Circle" />
        </div>
      </div>

      <VideoModal open={open} handleClose={handleClose} link={link} />
    </div>
  );
}

export default LandingPage;
