import { useContext, useEffect, useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MaterialReactTable,
  createRow,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AuthContext } from "../../../../context/authContext";
import { useMsal } from "@azure/msal-react";
import AddIcon from "@mui/icons-material/Add";
import paths from "../../../../api/paths.api";
import { useNavigate } from "react-router-dom/dist";
import CloseIcon from "@mui/icons-material/Close";

export const EditableTable = ({
  name,
  columns,
  actions,
  hooks,
  editMode,
  enableEditing,
  enableDelete = false,
  enableArchive,
  additionalProps,
  initialData,
  enableCreate,
  data,
}) => {
  const { accessToken } = useContext(AuthContext);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newProjectData, setNewProjectData] = useState(initialData || {});
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const navigateToTaskList = (row) => {
    // get id of project here
    const id = row.original.id;
    navigate(`${paths.myProjects}/${id}`);
  };
  // Call CREATE hook
  const { mutateAsync: createItem, isPending: isCreatingItem } =
    hooks.useCreateItem();

  //call UPDATE hook
  const { mutateAsync: updateItem, isPending: isUpdatingItem } =
    hooks.useUpdateItem();
  //call DELETE hook
  const { mutateAsync: deleteItem, isPending: isDeletingItem } =
    hooks.useDeleteItem();

  //UPDATE action
  const handleSaveItem = async ({ values, row, table }) => {
    const newValidationErrors = actions.validateItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    // console.log({ values, row })
    await updateItem({ values: { ...values, id: row.id }, accessToken });
    table.setEditingRow(null); //exit editing mode
  };

  // CREATE action
  const handleCreateItem = async () => {
    const newValidationErrors = actions.validateItem(newProjectData);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const { members, ...restData } = newProjectData; // Exclude members during creation
    await createItem({ values: restData, accessToken });
    setOpenCreateDialog(false); // Close the dialog
    setNewProjectData(initialData); // Reset form
  };

  const handleFieldChange = (field, value) => {
    setNewProjectData((prev) => ({ ...prev, [field]: value }));
    setValidationErrors((prev) => ({ ...prev, [field]: "" })); // Clear validation errors
  };

  return (
    <Box>
      <Button
        sx={{ display: enableCreate ? "flex" : "none", mb: 2 }}
        disabled={!enableCreate || isCreatingItem}
        variant="contained"
        startIcon={<AddIcon />}
        color="secondary"
        onClick={() => setOpenCreateDialog(true)}
        // sx={{ mb: 2 }}
      >
        Add {name}
      </Button>

      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
      >
        <DialogTitle>
          Create New {name}
          <IconButton
            sx={{ position: "absolute", right: 8, top: 8 }}
            onClick={() => setOpenCreateDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Project Name"
            value={newProjectData.projectName || ""}
            onChange={(e) => handleFieldChange("projectName", e.target.value)}
            error={!!validationErrors.projectName}
            helperText={validationErrors.projectName}
            required
          />
          <FormControl fullWidth>
            <InputLabel>Client</InputLabel>
            <Select
              value={newProjectData.clientName || ""}
              onChange={(e) => handleFieldChange("clientName", e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {additionalProps.clients?.map((client, index) => (
                <MenuItem key={index} value={client}>
                  {client}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={newProjectData.type || ""}
              onChange={(e) => handleFieldChange("type", e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {additionalProps.categories?.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateItem}
            color="secondary"
            variant="contained"
            disabled={isCreatingItem}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialReactTable
        columns={columns}
        data={data || []}
        // ...styledTableProps,
        enablePagination={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            // console.log("hhhhere", row);

            if (!event.target.closest(".MuiButtonBase-root")) {
              navigateToTaskList(row);
            }
          },
          sx: {
            cursor: "pointer",
          },
        })}
        enableEditing={enableEditing}
        onEditingRowSave={handleSaveItem}
        // muiTableBodyRowProps={{
        //   sx: {
        //     cursor: enableEditing ? "pointer" : "default",
        //   },
        // }}
      />
    </Box>
  );
};
