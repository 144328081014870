import { Box, Typography, Button } from "@mui/material";
import SectionModal from "./FormModal";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import Section from "./Section";
import { secondAxios } from "../../../api/axios";
import WideImage from "./WideImage";
import automationIMage from "./img/design autom.png";
import PersistLogin from "../../../components/RoutesHandler/PersistLogin";

export default function SolutionPage() {
  const [modaleState, setModalState] = useState(false);
  const [sections, setSections] = useState([]);
  const [refresh, setRefresh] = useState(false);
  // const { auth } = useContext(AuthContext);
  const { accessToken, auth } = useContext(AuthContext);

  console.log(auth);
  useEffect(() => {
    secondAxios.get("/solutionsection").then((res) => {
      console.log(res);
      setSections(res.data);
    });
  }, []);

  const handleDeleteSection = async (sectionId) => {
    await secondAxios.delete(`/solutionsection/${sectionId}`);
    const newSections = sections.filter((s) => s.id !== sectionId);
    setSections(newSections);
  };

  const handleClose = () => {
    setModalState(false);
  };
  const handleSave = async (data) => {
    console.log(data);
    await secondAxios.post("/solutionsection", {
      header: data.header,
      body: data.paragraph,
      vidUrl: data?.video?.name,
      imgUrl: data?.image?.name,
    });
    setSections((prevState) => [
      ...prevState,
      {
        header: data.header,
        body: data.paragraph,
        vidUrl: data?.video?.name,
        imgUrl: data?.image?.name,
      },
    ]);
    if (data.video) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      await secondAxios.post(
        "/solutionsection/upload-video",
        { file: data.video },
        config
      );
    }
    if (data.image) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      await secondAxios.post(
        "/solutionsection/upload-image",
        { file: data.image },
        config
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#b7b8b6",
      }}
    >
      <PersistLogin>
        <WideImage src={automationIMage} alt={"design automation"} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {sections.map((section) => (
            <Section
              section={section}
              role={auth.role}
              handleDeleteSection={handleDeleteSection}
            />
          ))}
          <SectionModal
            open={modaleState}
            handleClose={handleClose}
            handleSave={handleSave}
          />
        </Box>
        {auth.role === "admin" ||
          (auth.role === "owner" && (
            <Box
              sx={{ display: "flex", height: 100 }}
              justifyContent={"center"}
              alignContent={"center"}
              width="100%"
            >
              <Button
                variant="contained"
                onClick={() => {
                  console.log(auth.role);
                  setModalState(true);
                }}
                sx={{ maxHeight: 50 }}
              >
                add Section
              </Button>
            </Box>
          ))}
      </PersistLogin>
    </Box>
  );
}
