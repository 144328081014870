import React, { useState } from "react";
import { Chip, Tooltip, Box, IconButton, MenuItem } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { convertDateString } from "../../../../utils/convertDateString";
import { categories } from "../utils";

export const columns = (users, clients, openMembersModal) => [
  {
    accessorKey: "id",
    header: "Id",
    enableEditing: false,
    size: 80,
    Edit: () => <></>,
  },
  {
    accessorKey: "createdAt",
    header: "Start",
    enableEditing: false,
    size: 80,
    Cell: ({ cell }) => (
      <>
        {cell.getValue() ? (
          <div style={{ whiteSpace: "wrap" }}>
            {convertDateString(cell.getValue())}
          </div>
        ) : (
          ""
        )}
      </>
    ),
    Edit: () => <></>,
  },
  {
    accessorKey: "projectName",
    header: "Project Name",
    size: 500,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: true,
    }),
  },
  {
    accessorKey: "clientName",
    header: "Client",
    size: 50,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: false,
      select: true,
      children: clients.map((c, i) => (
        <MenuItem key={i} value={c}>
          {c}
        </MenuItem>
      )),
    }),
  },
  {
    accessorKey: "type",
    header: "Project Type",
    size: 100,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: false,
      select: true,
      children: categories.map((category, i) => (
        <MenuItem key={i} value={category}>
          {category}
        </MenuItem>
      )),
    }),
  },
  {
    accessorKey: "members",
    header: "Members",
    size: 150,
    Cell: ({ row, cell }) => {
      const members = cell.getValue();
      console.log("mmmmmm", members);

      const visibleMembers = members?.slice(0, 2);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box>
            {visibleMembers?.map((member, index) => (
              <Chip key={index} label={member.user.username} size="small" />
            ))}
            {members?.length > 2 && (
              <Tooltip title={members.slice(2).join(", ")} arrow>
                <Chip label={`${members.length - 2}+`} size="small" />
              </Tooltip>
            )}
          </Box>
          <IconButton onClick={() => openMembersModal(row.original.id)}>
            <InfoIcon />
          </IconButton>
        </Box>
      );
    },
    enableEditing: false,
  },
];
