import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#1e1e1e",
  border: "2px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 1,
};

const VideoModal = ({ open, handleClose, link }) => {
  const videoUrl =
    "https://enerfacas.s3.eu-north-1.amazonaws.com/tank+software+demo.mp4";
  // const videoUrl =
  //   `${process.env.REACT_APP_API}/videos/Tank_Software.mp4`;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={style}>
          {/* <h2 id="video-modal-title">Video Player</h2> */}
          <video
            width="100%"
            controls
            autoPlay
            controlsList="nodownload"
            src={link}
          >
            {/* <source src={videoUrl} type="video/mp4" /> */}
            Your browser does not support the video tag.
          </video>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoModal;
