import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Chip,
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { AuthContext } from "../../../../context/authContext";
import axios from "../../../../api/axios";

const ProjectMembersModal = ({ open, onClose, projectId }) => {
  //   const [allUsers, setAllUsers] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);
  const [newMember, setNewMember] = useState({ userId: "", role: "Engineer" });
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [saving, setSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const { role } = useContext(AuthContext);

  // Fetch project members
  useEffect(() => {
    if (open) {
      const fetchProjectMembers = async () => {
        try {
          setLoadingMembers(true);
          const { data } = await axios.get(`/project/${projectId}`);
          console.log("data", data);

          setProjectMembers(
            data.project_members.map((m) => ({ ...m.user, role: m.role })) || []
          );
        } catch (error) {
          console.error("Error fetching project members:", error);
        } finally {
          setLoadingMembers(false);
        }
      };
      fetchProjectMembers();
    }
  }, [open, projectId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = (await axios.get("/dev/all")).data;
        console.log("uuu", response);

        setUsers(response); // Assuming the API returns an array of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Add member
  const handleAddMember = async () => {
    if (!newMember.userId) return;

    try {
      setSaving(true);
      await axios.post("/project/add-member", {
        projectId,
        ...newMember,
      });
      const newUser = users.find((user) => user.id === newMember.userId);
      console.log("new member", newMember, users, "new ", newUser);
      setProjectMembers((prev) => [
        ...prev,
        {
          id: newMember.userId,
          username: users.find((user) => user.id === newMember.userId).username,
          role: newMember.role,
        },
      ]);
      setNewMember({ userId: "", role: "Engineer" });
    } catch (error) {
      console.error("Error adding member:", error);
    } finally {
      setSaving(false);
    }
  };

  // Remove member
  const handleRemoveMember = async (userId) => {
    try {
      setSaving(true);
      await axios.post("/project/remove-member", {
        projectId,
        userId,
      });
      setProjectMembers((prev) =>
        prev.filter((member) => member.id !== userId)
      );
    } catch (error) {
      console.error("Error removing member:", error);
    } finally {
      setSaving(false);
    }
  };

  if (loadingMembers || loadingUsers) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Project Members
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Members</Typography>
        <Box>
          {projectMembers.map((member) => (
            <Box
              key={member.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 1,
              }}
            >
              <Chip label={`${member.username} (${member.role})`} />
              {role === "admin" && (
                <IconButton
                  color="error"
                  onClick={() => handleRemoveMember(member.id)}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {role === "admin" && (
          <>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Add Member
            </Typography>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                labelId="user-select-label"
                value={newMember.userId}
                onChange={(e) =>
                  setNewMember((prev) => ({ ...prev, userId: e.target.value }))
                }
              >
                {users
                  .filter(
                    (user) =>
                      !projectMembers.some((member) => member.id === user.id)
                  )
                  .map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Select Role</InputLabel>
              <Select
                labelId="role-select-label"
                value={newMember.role}
                onChange={(e) =>
                  setNewMember((prev) => ({ ...prev, role: e.target.value }))
                }
              >
                <MenuItem value="Engineer">Engineer</MenuItem>
                <MenuItem value="Senior Engineer">Senior Engineer</MenuItem>
                <MenuItem value="Project Manager">Project Manager</MenuItem>
              </Select>
            </FormControl>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddMember}
              fullWidth
              disabled={saving || !newMember.userId}
            >
              Add Member
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectMembersModal;
