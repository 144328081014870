import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import ProjectList from "./ProjectList";
// import { getAllProjects } from './api';
import { useQuery } from "@tanstack/react-query";
import { Typography } from "@mui/material";
// import { ProjectContextProvider } from '../../../context/projectContext';

const ProjectsPage = () => {
  return (
    // <ProjectContextProvider>
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <Box sx={{ m: 4, width: "100%", pr: 3 }}>
        <Typography variant="h4" color="secondary">
          Projects
        </Typography>
        <ProjectList />
      </Box>
    </Box>
    // </ProjectContextProvider>
  );
};

export default ProjectsPage;
