import React, { useState, useEffect } from "react";
// import axios from "axios";
import axios from "../../../api/axios";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Container,
  Typography,
  Modal,
  TextField,
} from "@mui/material";

const ClientPage = () => {
  const [clients, setClients] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [editClient, setEditClient] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get("/clients/all");
      setClients(response.data);
    } catch (error) {
      console.error("Failed to fetch clients:", error);
    }
  };

  const handleAddClient = async () => {
    try {
      await axios.post("/clients/create", { name: newClientName });
      fetchClients();
      setOpenModal(false);
      setNewClientName("");
    } catch (error) {
      console.error("Failed to create client:", error);
    }
  };

  const handleEdit = (client) => {
    setEditClient(client);
    setNewClientName(client.name);
    setOpenModal(true);
  };

  const handleUpdateClient = async () => {
    try {
      await axios.put("/clients/update", {
        id: editClient.id,
        name: newClientName,
      });
      fetchClients();
      setEditClient(null);
      setOpenModal(false);
      setNewClientName("");
    } catch (error) {
      console.error("Failed to update client:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/clients/${id}`);
      fetchClients();
    } catch (error) {
      console.error("Failed to delete client:", error);
    }
  };

  const columns = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(row.original.id)}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
        }}
      >
        <Typography variant="h5">Client List</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          New Client
        </Button>
      </div>
      <MaterialReactTable
        columns={columns}
        data={clients}
        enableRowSelection={false}
        enableColumnOrdering
        muiTableContainerProps={{
          sx: { maxHeight: "500px" },
        }}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            {editClient ? "Edit Client" : "New Client"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Client Name"
            value={newClientName}
            onChange={(e) => setNewClientName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={editClient ? handleUpdateClient : handleAddClient}
            style={{ marginTop: 16 }}
          >
            {editClient ? "Update Client" : "Add Client"}
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ClientPage;
