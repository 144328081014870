import React, { createContext, useEffect, useState } from "react";
import axios from "../api/axios";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

export const loginVerify = async (token) => {
  console.log("token in authh", token);
  const response = await axios.get("/dev/verifyToken", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("resssssssssssssssssssss", response);

  return response;
};

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const { instance, inProgress, accounts } = useMsal();
  const [auth, setAuth] = useState({});
  const [role, setRole] = useState(null);

  const getAccessToken = async () => {
    // console.log({ inProgress })
    const accessTokenRequest = {
      scopes: [
        `api://${process.env.REACT_APP_AZURE_AD_SERVER_CLIENT_ID}/specialScope`,
      ],
      account: accounts[0],
    };
    if (accounts[0] && inProgress === InteractionStatus.None) {
      return await instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
          return accessToken;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setAccessToken(accessToken);
                return accessToken;
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    } else {
      return null;
    }
  };

  useEffect(() => {
    getAccessToken();

    const getAccessTokenOnFocus = () => {
      getAccessToken();
    };

    // Add event listener for the 'focus' event
    window.addEventListener("focus", getAccessTokenOnFocus);

    // Set up interval to call getAccessToken every half an hour (1800000 milliseconds)
    const intervalId = setInterval(() => {
      getAccessToken();
    }, 1800000);

    // Clean up the interval when the component unmounts or dependencies change
    return () => {
      clearInterval(intervalId);
    };
  }, [instance, accounts, inProgress]);
  useEffect(() => {
    if (accessToken) {
      console.log("verifyingggggggg");

      const getAuth = async () => {
        const resp = await loginVerify(accessToken);
        setRole(resp.data.role);
      };
      getAuth();
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{ getAccessToken, accessToken, auth, setAuth, role }}
    >
      {children}
    </AuthContext.Provider>
  );
};
