import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { sidebarWidth } from "../../utils";

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

const ProjectListSideBar = ({
  projects,
  selectedProject,
  setSelectedProject,
}) => {
  const navigate = useNavigate();
  const userId = "user123"; // Replace with actual user ID
  const localStorageKey = `projectOrder_${userId}`;

  const orderedProjects = useMemo(() => {
    const savedOrder = localStorage.getItem(localStorageKey);
    if (savedOrder && savedOrder.length !== 0 && savedOrder !== "undefined") {
      let order = JSON.parse(savedOrder);
      return order
        ?.map((id) => projects.find((project) => project.id === id))
        .filter(Boolean)
        .filter((project) => !project.isArchived);
    } else {
      return projects;
    }
  }, [projects, localStorageKey]);

  const [currentProjects, setCurrentProjects] = useState(orderedProjects);

  useEffect(() => {
    setCurrentProjects(orderedProjects);
  }, [orderedProjects]);

  useEffect(() => {
    console.log("ppp", projects);

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(currentProjects?.map((project) => project.id))
    );
  }, [currentProjects, localStorageKey]);

  const handleProjectClick = (project) => {
    navigate(`/projects/${project.id}`);
    setSelectedProject(project);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedProjects = Array.from(currentProjects);
    const [removed] = reorderedProjects.splice(result.source.index, 1);
    reorderedProjects.splice(result.destination.index, 0, removed);

    setCurrentProjects(reorderedProjects);
  };

  return (
    <Box display={"flex"} sx={{ minHeight: "95vh", position: "fixed" }}>
      <Drawer
        variant="permanent"
        open={true}
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sidebarWidth,
            boxSizing: "border-box",
            position: "relative",
            boxShadow: "rgba(0, 0, 0, 0.15) 0.95px 1.95px 2.6px;",
          },
          maxHeight: "95vh",
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
            Projects
          </Typography>
        </Toolbar>
        <Divider />
        <Box sx={{ overflow: "auto" }}>
          {currentProjects?.length ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-projects">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {currentProjects.map((project, index) => (
                      <Draggable
                        key={project.id.toString()}
                        draggableId={project.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            disablePadding
                          >
                            <ListItemButton
                              selected={selectedProject?.id === project.id}
                              onClick={() => handleProjectClick(project)}
                            >
                              <ListItemText primary={project.projectName} />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <ListItem>No Projects</ListItem>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ProjectListSideBar;
